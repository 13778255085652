// #region Cognitouser
export const COGNITOUSER_CHCKIFEMAILVERIFIED_REQUEST = "[COGNITOUSER] CHECK IF EMAIL VERIFIED REQUEST";
export const COGNITOUSER_CHCKIFEMAILVERIFIED_SUCCESS = "[COGNITOUSER] CHECK IF EMAIL VERIFIED SUCCESS";
export const COGNITOUSER_CHCKIFEMAILVERIFIED_FAILURE = "[COGNITOUSER] CHECK IF EMAIL VERIFIED FAILURE";

export const COGNITOUSER_VERIFY_EMAIL_REQUEST = "[COGNITOUSER] VERIFY EMAIL REQUEST";
export const COGNITOUSER_VERIFY_EMAIL_SUCCESS = "[COGNITOUSER] VERIFY EMAIL SUCCESS";
export const COGNITOUSER_VERIFY_EMAIL_FAILURE = "[COGNITOUSER] VERIFY EMAIL FAILURE";

export const COGNITOUSER_GETCONFIRMATIONSTATUS_REQUEST = "[COGNITOUSER] GET CONFIRMATIONSTATUS REQUEST";
export const COGNITOUSER_GETCONFIRMATIONSTATUS_SUCCESS = "[COGNITOUSER] GET CONFIRMATIONSTATUS SUCCESS";
export const COGNITOUSER_GETCONFIRMATIONSTATUS_FAILURE = "[COGNITOUSER] GET CONFIRMATIONSTATUS FAILURE";

export const COGNITOUSER_UPDATEINFO_REQUEST = "[COGNITOUSER] UPDATEINFO REQUEST";
export const COGNITOUSER_UPDATEINFO_SUCCESS = "[COGNITOUSER] UPDATEINFO SUCCESS";
export const COGNITOUSER_UPDATEINFO_FAILURE = "[COGNITOUSER] UPDATEINFO FAILURE";

export const COGNITOUSER_REGISTER_REQUEST = "[COGNITOUSER] REGISTER REQUEST";
export const COGNITOUSER_REGISTER_SUCCESS = "[COGNITOUSER] REGISTER SUCCESS";
export const COGNITOUSER_REGISTER_FAILURE = "[COGNITOUSER] REGISTER FAILURE";

export const COGNITOUSER_LOGIN_REQUEST = "[COGNITOUSER] LOGIN REQUEST";
export const COGNITOUSER_LOGIN_SUCCESS = "[COGNITOUSER] LOGIN SUCCESS";
export const COGNITOUSER_LOGIN_FAILURE = "[COGNITOUSER] LOGIN FAILURE";

export const COGNITOUSER_LOGIN_WITH_TOKEN_REQUEST = "[COGNITOUSER] LOGIN WITH TOKEN REQUEST";
export const COGNITOUSER_LOGIN_WITH_TOKEN_SUCCESS = "[COGNITOUSER] LOGIN WITH TOKEN SUCCESS";
export const COGNITOUSER_LOGIN_WITH_TOKEN_FAILURE = "[COGNITOUSER] LOGIN WITH TOKEN FAILURE";

export const COGNITOUSER_LOGOUT = "[COGNITOUSER] LOGOUT";

export const RESEND_EMAIL_REQUEST = "[USER] RESEND EMAIL REQUEST";
export const RESEND_EMAIL_SUCCESS = "[USER] RESEND EMAIL SUCCESS";
export const RESEND_EMAIL_FAILURE = "[USER] RESEND EMAIL FAILURE";

export const FORGOT_PASSWORD_EMAIL_REQUEST = "[USER] [FORGOT_PASSWORD] EMAIL_REQUEST";
export const FORGOT_PASSWORD_EMAIL_SUCCESS = "[USER] [FORGOT_PASSWORD] EMAIL_SUCCESS";
export const FORGOT_PASSWORD_EMAIL_FAILURE = "[USER] [FORGOT_PASSWORD] EMAIL_FAILURE";

export const RESET_PASSWORD_REQUEST = "[USER] RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "[USER] RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "[USER] RESET_PASSWORD_FAILURE";

// #endregion

// #region Order
export const GET_ORDER_LIST_REQUEST = "[ORDER] GET LIST REQUEST";
export const GET_ORDER_LIST_SUCCESS = "[ORDER] GET LIST SUCCESS";
export const GET_ORDER_LIST_FAILURE = "[ORDER] GET LIST FAILURE";

export const GET_ORDER_DOCUMENT_REQUEST = "[ORDER] GET ORDER DOCUMENT REQUEST";
export const GET_ORDER_DOCUMENT_SUCCESS = "[ORDER] GET ORDER DOCUMENT SUCCESS";
export const GET_ORDER_DOCUMENT_FAILURE = "[ORDER] GET ORDER DOCUMENT FAILURE";

export const CONVERT_ORDER_DOCUMENT_ORIGINAL_TO_BATE_REQUEST = "[ORDER] CONVERT ORDER DOCUMENT ORIGINAL TO BATE REQUEST";
export const CONVERT_ORDER_DOCUMENT_ORIGINAL_TO_BATE_SUCCESS = "[ORDER] CONVERT ORDER DOCUMENT ORIGINAL TO BATE SUCCESS";
export const CONVERT_ORDER_DOCUMENT_ORIGINAL_TO_BATE_FAILURE = "[ORDER] CONVERT ORDER DOCUMENT ORIGINAL TO BATE FAILURE";

export const DELETE_ORDER_DOCUMENT_REQUEST = "[ORDER] DELETE ORDER DOCUMENT REQUEST";
export const DELETE_ORDER_DOCUMENT_SUCCESS = "[ORDER] DELETE ORDER DOCUMENT SUCCESS";
export const DELETE_ORDER_DOCUMENT_FAILURE = "[ORDER] DELETE ORDER DOCUMENT FAILURE";

export const CONVERT_ORDER_ORIGINAL_TO_BATES_REQUEST = "[ORDER] CONVERT ORIGINAL TO BATES REQUEST";
export const CONVERT_ORDER_ORIGINAL_TO_BATES_SUCCESS = "[ORDER] CONVERT ORIGINAL TO BATES SUCCESS";
export const CONVERT_ORDER_ORIGINAL_TO_BATES_FAILURE = "[ORDER] CONVERT ORIGINAL TO BATES FAILURE";

export const PRINT_ORDER_DOCUMENT = "[ORDER] PRINT ORDER DOCUMENT";
// #endregion

// #region Court
export const GET_COURT_LIST_REQUEST = "[COURT] GET LIST REQUEST";
export const GET_COURT_LIST_SUCCESS = "[COURT] GET LIST SUCCESS";
export const GET_COURT_LIST_FAILURE = "[COURT] GET LIST FAILURE";
// #endregion

// #region Cellphone Carrier
export const GET_CELLPHONE_CARRIER_LIST_REQUEST = "[CELLPHONECARRIER] GET LIST REQUEST";
export const GET_CELLPHONE_CARRIER_LIST_SUCCESS = "[CELLPHONECARRIER] GET LIST SUCCESS";
export const GET_CELLPHONE_CARRIER_LIST_FAILURE = "[CELLPHONECARRIER] GET LIST FAILURE";
// #endregion
